<template>
  <div
    v-on-clickaway="onBlur"
    :class="$options.consts.WRAPPER_CLASS"
    @blur.capture="onElementBlur"
  >
    <a-visibility show :on="[$breakpoint.mobile]">
      <a-text-input
        v-model="searchPhrase"
        :focus-on-mount="focusOnMount"
        :max-length="$options.consts.QUICK_SEARCH_MAX_LENGTH_LIMITATION"
        search-icon
        :input-style="$options.consts.INPUT_STYLE.STYLE_2"
        :in-progress="inProgress"
        :hide-label="hideLabel"
        :placeholder="placeholder"
        ref="search"
        @focus="isFocused = true"
        @search="openSearchPage"
        @keyup.enter="openSearchPage"
      />
    </a-visibility>
    <a-visibility hide :on="[$breakpoint.mobile]">
      <a-text-input
        v-model="searchPhrase"
        :focus-on-mount="focusOnMount"
        :max-length="$options.consts.QUICK_SEARCH_MAX_LENGTH_LIMITATION"
        search-icon
        :input-style="$options.consts.INPUT_STYLE.STYLE_1"
        :in-progress="inProgress"
        :hide-label="hideLabel"
        :placeholder="placeholder"
        ref="search"
        @focus="isFocused = true"
        @search="openSearchPage"
        @keyup.enter="openSearchPage"
      />
    </a-visibility>
    <component
      :is="quicksearchResultsComponent"
      v-if="isDropdownVisible"
      :search-phrase="searchPhrase"
      :in-progress.sync="inProgress"
      :single-column="singleColumn"
      :source="source"
      :scrollable="scrollable"
      v-on="$listeners"
      @search="openSearchPage"
      @hook:mounted="isFocused = true"
    />

    <i
      v-if="isCloseIconVisible"
      class="close-icon close-icon_sm"
      @click="onBlur"
    />
  </div>
</template>

<script>
import { directive as onClickaway } from 'vue-clickaway'

import mixins from '@/utils/mixins'
import { propValidator, PROP_TYPES } from '@/utils/validators'
import { FM_DIR_ROUTE, ROUTE_NAME } from 'enums/routes'
import { INPUT_STYLE } from 'shared/ATextInput'
import { CLOSE_MOBILE_HEADER_MENU_EVENT } from '@/components/AHeader/AHeaderMobile/enums'
import { QUICKSEARCH_SOURCE } from 'shared/AGlobalQuicksearch/enums'
import { MIN_CHAR_COUNT_FOR_TRACKING_SEARCH } from '@/utils/mixins/track-search'
import { mapMutations } from 'vuex'
import * as types from '@/store/mutation-types'
import { SEARCH_TRACKING_EVENT_TYPE } from 'enums/track-search'
import { hasParentWith } from '@fmpedia/helpers'
import { hydrateWhenVisible } from '@/utils/helpers/vue-lazy-hydration/LazyHydrate'
import { QUICK_SEARCH_MAX_LENGTH_LIMITATION } from 'enums/quicksearch'

export const MIN_CHAR_COUNT_FOR_SEARCH = 2

const QUICKSEARCH_SOURCES = Object.values(QUICKSEARCH_SOURCE)

const QUICKSEARCH_RESULTS_COMPONENT_BY_SOURCE = {
  [QUICKSEARCH_SOURCE.FM]: 'FmQuicksearchResults',
  [QUICKSEARCH_SOURCE.FM_DIR]: 'FmDirQuicksearchResults'
}

const WRAPPER_CLASS = 'global-quicksearch__wrapper'

export default {
  name: 'AGlobalQuicksearch',
  mixins: [mixins.urlGenerators, mixins.trackSearch],
  components: {
    FmDirQuicksearchResults: hydrateWhenVisible(
      () => import('./FmDirQuicksearchResults'),
      {
        props: ['search-phrase', 'in-progress', 'single-column', 'scrollable']
      }
    ),
    FmQuicksearchResults: hydrateWhenVisible(
      () => import('./FmQuicksearchResults'),
      {
        props: ['search-phrase', 'in-progress', 'single-column', 'scrollable']
      }
    )
  },
  directives: { onClickaway },
  props: {
    singleColumn: propValidator([PROP_TYPES.BOOLEAN], false, false),
    hideLabel: propValidator([PROP_TYPES.BOOLEAN], false, false),
    source: propValidator(
      [PROP_TYPES.STRING],
      false,
      QUICKSEARCH_SOURCE.FM,
      value => QUICKSEARCH_SOURCES.includes(value)
    ),
    placeholder: propValidator(
      [PROP_TYPES.STRING],
      false,
      'What are you looking for?'
    ),
    withCloseIcon: propValidator([PROP_TYPES.BOOLEAN], false, false),
    focusOnMount: propValidator([PROP_TYPES.BOOLEAN], false, false),
    useTrackSearch: propValidator([PROP_TYPES.BOOLEAN], false, true),
    scrollable: propValidator([PROP_TYPES.BOOLEAN], false, false)
  },
  consts: { INPUT_STYLE, WRAPPER_CLASS, QUICK_SEARCH_MAX_LENGTH_LIMITATION },
  data() {
    return {
      searchPhrase: '',
      isFocused: false,
      inProgress: false,
      searchTrackingEventBySource: {
        [QUICKSEARCH_SOURCE.FM]: SEARCH_TRACKING_EVENT_TYPE.FM_GLOBAL_SEARCH,
        [QUICKSEARCH_SOURCE.FM_DIR]:
          SEARCH_TRACKING_EVENT_TYPE.FMDIR_GLOBAL_SEARCH
      }
    }
  },
  computed: {
    currentTrackingEventType() {
      return this.searchTrackingEventBySource[this.source]
    },
    quicksearchResultsComponent() {
      return QUICKSEARCH_RESULTS_COMPONENT_BY_SOURCE[this.source]
    },
    isDropdownVisible() {
      return (
        (this.isFocused || this.$refs?.search?.isFocused) &&
        this.searchPhrase.length >= MIN_CHAR_COUNT_FOR_SEARCH &&
        this.$helper.isTrimmedStringNotEmpty(this.searchPhrase)
      )
    },
    isCloseIconVisible() {
      return this.withCloseIcon && !this.inProgress
    }
  },
  watch: {
    searchPhrase: function() {
      if (
        this.useTrackSearch &&
        this.searchPhrase?.length >= MIN_CHAR_COUNT_FOR_TRACKING_SEARCH
      ) {
        this.$_trackSearch_debouncedTrackSearch(
          this.searchPhrase,
          this.currentTrackingEventType
        )
      }
    }
  },
  methods: {
    ...mapMutations({
      setSkipSearchTrackOnSearchResultsPageStatus: `globalSearch/${types.SET_SKIP_SEARCH_TRACK_ON_SEARCH_RESULTS_PAGE_STATUS}`
    }),
    openSearchPage() {
      if (this.searchPhrase.length < MIN_CHAR_COUNT_FOR_SEARCH) return

      if (this.source === QUICKSEARCH_SOURCE.FM) {
        this.$router.push({
          name: ROUTE_NAME.SEARCH_RESULTS,
          query: {
            searchPhrase: this.searchPhrase
          }
        })

        this.setSkipSearchTrackOnSearchResultsPageStatus(true)

        this.resetInput()
        this.$bus.$emit(CLOSE_MOBILE_HEADER_MENU_EVENT)
      } else {
        const queryString = this.$helper.serializeQueryParams({
          searchPhrase: this.searchPhrase
        })
        const link = `${this.generateFmDirUrl(
          FM_DIR_ROUTE.SEARCH_RESULTS
        )}?${queryString}`

        window.location.replace(link)
      }
    },
    resetInput() {
      this.searchPhrase = ''
      this.onBlur()
    },
    onElementBlur(event) {
      const { relatedTarget } = event

      if (
        relatedTarget &&
        !hasParentWith(event.relatedTarget, {
          className: WRAPPER_CLASS
        })
      ) {
        this.onBlur()
      }
    },
    onBlur() {
      this.$nextTick(() => {
        this.isFocused = false
        this.$emit('blur')
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.global-quicksearch__wrapper {
  position: relative;
  max-width: 800px;

  /deep/ .input__input:focus {
    outline: none;
  }
}

.close-icon {
  right: 8px;
  top: 8px;
}
</style>
